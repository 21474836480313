@import '../../scss/theme-bootstrap';

.content-block-formatter,
.mpp-custom-override-layout {
  .slick-slider {
    .slick-list {
      @include swap_direction(margin, 0 0 0 -2px);
      width: calc(100% + 4px);
      @include breakpoint($bp--large-up) {
        @include swap_direction(margin, 0 -10px 0 -10px);
        width: calc(100% + 20px);
      }
    }
  }
}
.elc-search-results-wrapper,
.elc-search-enhanced-grid-wrapper,
.sd-product-grid,
.content-block-formatter,
.mpp-custom-override-layout {
  .elc-slider-view-wrapper {
    padding: 0;
  }
  .slick-slider:not(.content-block--slider) {
    padding-top: 10px;
    padding-bottom: 40px;
    @include breakpoint($bp--large-up) {
      padding-bottom: 10px;
      padding-top: 60px;
    }
  }
  .slick-slider {
    .slick-slide {
      max-width: unset;
    }
    .slick-track {
      display: flex;
    }
    .slick-disabled {
      .elc-slider-arrow-left,
      .elc-slider-arrow-right {
        opacity: 0.3;
        cursor: default;
      }
    }
    .slick-prev,
    .slick-next {
      display: none;
      width: 48px;
      height: 48px;
      position: absolute;
      top: 10px;
      @include breakpoint($bp--large-up) {
        display: block;
      }
      &:before {
        content: '';
      }
    }
    .slick-prev {
      #{$ldirection}: unset;
      #{$rdirection}: 75px;
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--black.svg');
      &.elc-slider-arrow-wrapper {
        #{$ldirection}: unset;
      }
    }
    .slick-next {
      #{$rdirection}: 0;
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--black.svg');
    }
    .brief-product-arrows {
      .slick-prev {
        #{$ldirection}: 0;
      }
      .slick-prev {
        [dir='rtl'] & {
          #{$ldirection}: unset;
        }
      }
      @if $cr21_mpp == false {
        .slick-next,
        .slick-prev {
          background: none;
        }
      }
    }

    .elc-product-carousel-container {
      .elc-slider-arrow-wrapper {
        &.slick-next {
          top: 12px;
          #{$rdirection}: 5px;
        }
        &.slick-prev {
          top: 12px;
          #{$ldirection}: unset;
          #{$rdirection}: 72px;
        }
      }
    }
    .elc-slider-arrow-left,
    .elc-slider-arrow-right {
      -webkit-mask: unset;
      mask: unset;
      width: 48px;
      height: 48px;
      transform: none;
    }
    .elc-slider-arrow-left {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--black.svg');
    }
    .elc-slider-arrow-right {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--black.svg');
    }

    .slick-dots,
    .elc-slider-dots-list {
      position: absolute;
      bottom: 10px;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      text-align: center;
      color: $color--black;
      display: block;
      @include breakpoint($bp--large-up) {
        display: none;
      }
      // override global styles here
      > li {
        display: inline-block;
        padding: 0;
        height: 6px;
        width: 6px;
        &.elc-slider-dot {
          margin: 0 2px;
        }
        button {
          cursor: default;
          height: 6px;
          width: 6px;
          padding: 0;
          &:before {
            height: 6px;
            line-height: 6px;
            width: 6px;
            font-size: 6px;
          }
          &.elc-slider-dot-button-active {
            &:before {
              color: $color--black;
              opacity: 0.75;
            }
          }
        }
      }
    }
  }
}

.dark-theme {
  .slick-slider {
    .slick-dots,
    .elc-slider-dots-list {
      > li {
        button {
          &:before {
            color: $color--white;
          }
          &.elc-slider-dot-button-active {
            &:before {
              opacity: 0.75;
              color: $color--white;
            }
          }
        }
      }
    }
    .slick-prev {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg');
    }
    .slick-next {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg');
    }

    .elc-slider-arrow-left {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-left-lrg--white.svg');
    }
    .elc-slider-arrow-right {
      background: url('#{$base-theme-path}svg-icons/src/icon--arrow-right-lrg--white.svg');
    }
  }
}

@mixin preload-row($value, $size, $breakpoint) {
  .#{$size}-items-per-row-#{$value} > div:not(.slick-list) {
    @if $breakpoint {
      :not(.slick-list) & {
        @include breakpoint($breakpoint) {
          display: none;
        }
      }

      &:nth-child(-n + #{$value}) {
        :not(.slick-slist) & {
          @include breakpoint($breakpoint) {
            display: flex;
          }
        }
      }
    } @else {
      :not(.slick-list) & {
        display: none;
      }

      &:nth-child(-n + #{$value}) {
        :not(.slick-list) & {
          display: flex;
        }
      }
    }
  }
}

// Breakpoints helpers
$items-per-row: 1 2 3 4 5;

@each $value in $items-per-row {
  @include slider-preload-row($value, 'mobile', false);
  @include slider-preload-row($value, 'desktop', $bp--xlarge-up);
  @include slider-preload-row($value, 'large', $bp--xxxlarge-up);
  @include slider-preload-row($value, 'extra_large', $bp--wide-up);
}
